<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand"
    :class="{ 'navbar-dark': type === 'default' }"
  >
    <a
      href="#"
      aria-current="page"
      class="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active"
    >
      {{ $t($route.name) }}
    </a>
    <!-- Navbar links -->
    <b-navbar-nav class="align-items-center ml-md-auto">
      <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
      <li class="nav-item d-sm-none">
        <a
          class="nav-link"
          href="#"
          data-action="search-show"
          data-target="#navbar-search-main"
        >
          <i class="ni ni-zoom-split-in"></i>
        </a>
      </li>
    </b-navbar-nav>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
      <b-nav-item-dropdown :text="this.current_locale" right>
        <b-dropdown-item href="#" @click="setLanguage('en')">English</b-dropdown-item>
        <b-dropdown-item href="#" @click="setLanguage('cn')">简体</b-dropdown-item>
        <b-dropdown-item href="#" @click="setLanguage('cn-t')">繁体</b-dropdown-item>
      </b-nav-item-dropdown>
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
          <b-media no-body class="align-items-center">
            <!--span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="img/theme/team-4.jpg" />
            </span -->
            <b-icon v-if="showOutbound" icon="info-circle-fill" variant="warning"></b-icon>
            <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">{{ myName }}</span>
            </b-media-body>
          </b-media>
        </a>

        <template>
          <b-dropdown-header v-if="showOutbound" class="noti-title">
            <h6 class="text-overflow m-0">{{ outboundQty }} Outbound request</h6>
          </b-dropdown-header>
          <b-dropdown-item v-if="showOutbound" href="#/outbound">
            <i class="ni ni-bold-right"></i>
            <span>{{ $t('Outbound') }} ({{ outboundQty }})</span>
          </b-dropdown-item>

          <div class="dropdown-divider"></div>
          <b-dropdown-item href="#/login">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </b-dropdown-item>
        </template>
      </base-dropdown>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { BaseNav } from "@/components";
import { getStore, setStore } from "../../util/helper";

const axios = require("axios");

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)"
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      outboundQty: 0,
      showOutbound: false,
      myName: "Hello",

      token: "",
      user_id: 0,
      current_locale: "",
    };
  },
  mounted() {
    let name = getStore('username');
    if (name && (name != undefined)) {
      this.myName = name;
    }
    let lang = getStore('lang');
    if ((lang != 'en') && (lang != 'cn') && (lang != 'cn-t')) {
      lang = 'cn';
    }
    this.$i18n.locale=lang;
    this.setLanguage(lang);
    this.init_data()
  },

  methods: {
    async get_notify() {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/notify",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.outboundQty = rt.data.outboundQty;
              if (that.outboundQty > 0) {
                that.showOutbound = true;
              } else {
                that.showOutbound = false;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        return;
      }
      return this.get_notify();
    },
    setLanguage(lang) {
      this.$i18n.locale=lang;
      setStore('lang', lang);
      if(this.$i18n.locale == "en"){
        this.current_locale = "English";
      } else if (this.$i18n.locale == "cn"){
        this.current_locale = "简体";
      } else if (this.$i18n.locale == "cn-t"){
        this.current_locale = "繁体";
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    }
  }
};
</script>
<style>
.main-content .navbar-top {
  background: linear-gradient(rgb(0, 117, 190), rgb(0, 91, 151));
  padding: 0px;

}
</style>
