<template v-slot:name="mobile-right">
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-xl navbar-light"
    :class="$sidebar.showSidebar ? '' : 'navbar-collapsed'"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="toggleSidebar"> </navbar-toggle-button>
      <router-link id="logo" class="navbar-brand" to="/" event @click.native.prevent="logoClick">
        <img :src="logo" class="navbar-brand-img" alt="POSKING LOGO" />
      </router-link>
      <router-link id="logo-collapse" class="navbar-brand" to="/" event @click.native.prevent="logoClick">
        <img :src="$sidebar.showSidebar ? logo : logo_collapse" class="navbar-brand-img" alt="POSKING LOGO" />
      </router-link>
      <navbar-expand-button @click.native="toggleSidebar"> </navbar-expand-button>

      <template>
        <ul class="nav align-items-center d-md-none">
          <b-nav-item-dropdown :text="this.current_locale" right>
            <b-dropdown-item href="#" @click="setLanguage('en')">English</b-dropdown-item>
            <b-dropdown-item href="#" @click="setLanguage('cn')">简体</b-dropdown-item>
            <b-dropdown-item href="#" @click="setLanguage('cn-t')">繁体</b-dropdown-item>
          </b-nav-item-dropdown>
          <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
            <a slot="title-container" class="nav-link" href="#" role="button">
              {{ myName }}
            </a>

            <!-- <div class=" dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i>
              <span>Settings</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-calendar-grid-58"></i>
              <span>Activity</span>
            </router-link>
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-support-16"></i>
              <span>Support</span>
            </router-link>
            <div class="dropdown-divider"></div> -->
            <a href="#/login" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Logout</span>
            </a>
          </base-dropdown>
        </ul>
      </template>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main" 
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--Divider-->
        <ul class="navbar-nav">
          <slot name="links-after"> </slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import NavbarExpandButton from "@/components/NavbarExpandButton";
import { getStore, setStore } from "../../util/helper";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
    NavbarExpandButton
  },
  props: {
    logo: {
      type: String,
      default: "img/brand/logo.jpg",
      description: "Sidebar app logo"
    },
    logo_collapse: {
      type: String,
      default: "img/brand/logo_s.png",
      description: "Sidebar app logo"
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item"
    }
  },
  data() {
    return {
      myName: "",
      current_locale: "",
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  mounted() {
    let name = getStore('username');
    if (name && (name != undefined)) {
      this.myName = name;
    }
    let lang = getStore('lang');
    if ((lang != 'en') && (lang != 'cn') && (lang != 'cn-t')) {
      lang = 'cn';
    }

    this.$i18n.locale=lang;
    this.setLanguage(lang);
  },
  methods: {
    logoClick() {
      console.log('Logo clicked');
    },
    setLanguage(lang) {
      this.$i18n.locale=lang;
      setStore('lang', lang);
      if(this.$i18n.locale == "en"){
        this.current_locale = "English";
      } else if (this.$i18n.locale == "cn"){
        this.current_locale = "简体";
      } else if (this.$i18n.locale == "cn-t"){
        this.current_locale = "繁体";
      }
    },
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    toggleSidebar() {
      this.$sidebar.toggleSidebar();
      if (!this.$sidebar.showSidebar) {
        this.$emit('click-close', '');
      }
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
